import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from './auth/AuthGuard';
import { authRoles } from './auth/authRoles';
import Loadable from './components/Loadable';
import MatxLayout from './components/MatxLayout/MatxLayout';
import materialRoutes from 'app/views/material-kit/MaterialRoutes';

// session pages
const NotFound = Loadable(lazy(() => import('app/views/sessions/NotFound')));
const JwtLogin = Loadable(lazy(() => import('app/views/sessions/JwtLogin')));
const JwtRegister = Loadable(lazy(() => import('app/views/sessions/JwtRegister')));
const ForgotPassword = Loadable(lazy(() => import('app/views/sessions/ForgotPassword')));
const NewPassword = Loadable(lazy(() => import('app/views/sessions/NewPassword')));
const ConfirmEmail = Loadable(lazy(() => import('app/views/sessions/ConfirmEmail')));

// echart page
const AppEchart = Loadable(lazy(() => import('app/views/charts/echarts/AppEchart')));

// dashboard page
const Analytics = Loadable(lazy(() => import('app/views/dashboard/Analytics')));

// Statistics pages
const IBHistorical = Loadable(lazy(() => import('app/views/statistics/initialbalance/IBHistorical')));
const Historical = Loadable(lazy(() => import('app/views/statistics/Historical')));

// Distribution pages
const RangeDistribution = Loadable(lazy(() => import('app/views/statistics/RangeDistribution')));

// Distribution pages
const Heatmap = Loadable(lazy(() => import('app/views/statistics/Heatmap')));

//---------
//IMPORTANT
//---------
// UPDATE THE auth/routeRoles.js when adding routes, navigation, dynamic URLs like for historical/xxxx and distribution/xxxxx
// in total, have to update: routeRoles.js + navigation.js + routes.jsx

const routes = [
  {
    element: (
      <AuthGuard >
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...materialRoutes,
      // dashboard route
      
      {
        path: '/dashboard/default',
        element: (
          //<AuthGuard roles={authRoles.admin}>
            <Analytics />
          //</AuthGuard>
        ),
      },

      // e-chart rooute
      {
        path: '/charts/echarts',
        element: <AppEchart />,
        auth: authRoles.user
      },

      //Statistics routes
      {
        path: '/initialbalance/historical',
        element: <IBHistorical />,
        auth: authRoles.guest
      },

      
      {
        path: '/historical/:hist',
        element: <Historical />,
        auth: authRoles.user
      },
      

      //Range Distribution routes
      {
        path: '/distribution/:dist',
        element: <RangeDistribution />,
        auth: authRoles.user
      },

      //heatmap routes
      {
        path: '/heatmap/:hm',
        element: <Heatmap />        
      }
    ]
  },
 /* {
    path: '/dashboard/default',
    element: (
      <AuthGuard roles={authRoles.user}>
        <MatxLayout>
          <Analytics />
        </MatxLayout>
      </AuthGuard>
    ),
  },

  {
    path: '/historical/:hist',
    element: (
      <AuthGuard roles={authRoles.user}>
      <Historical />
    </AuthGuard>
    ),
  },*/

  // session pages route
  { path: '/session/404', element: <NotFound /> },
  { path: '/session/signin', element: <JwtLogin /> },
  { path: '/session/signup', element: <JwtRegister /> },
  { path: '/session/forgot-password', element: <ForgotPassword /> },
  { path: '/session/new-password', element: <NewPassword /> },
  { path: '/session/confirm-email', element: <ConfirmEmail /> },

  { path: '/', element: <Navigate to="dashboard/default" /> },
  { path: '*', element: <NotFound /> }
];

export default routes;



export const API_BASE_URL = 'https://engine.marketstats.pro/';//'https://engine.marketstats.pro/';//'http://localhost:7000/'; //'https://engine.marketstats.pro/';
export const PRODUCTS = ['ES', 'NQ', 'CL', 'FDAX', 'FESX', 'FGBL'];
export const DISTRIBUTIONSTATS = [
    "DISTRIBUTION_IB",
    "DISTRIBUTION_EXTUP",
    "DISTRIBUTION_EXTDOWN",
    "DISTRIBUTION_SESSION",
    "DISTRIBUTION_VOLUME_SESSION",
    "DISTRIBUTION_VOLUME_OVN",
    "DISTRIBUTION_ROTATION_UP",
    "DISTRIBUTION_ROTATION_DOWN",
    "DISTRIBUTION_OR",
    "DISTRIBUTION_OR_BREAK1_UP",
    "DISTRIBUTION_OR_BREAK2_UP",
    "DISTRIBUTION_OR_BREAK1_DOWN",
    "DISTRIBUTION_OR_BREAK2_DOWN"
  ];
  
  export const DISTRIBUTIONSTATSNAMES = [
    "Initial Balance",
    "Extension Up",
    "Extension Down",
    "Session",
    "Session Volume",
    "Overnight Volume",
    "Rotation Up",
    "Rotation Down",
    "Opening Range",
    "Opening Range Break 1 Up",
    "Opening Range Break 2 Up",
    "Opening Range Break 1 Down",
    "Opening Range Break 2 Down"
  ];
export const DISTRIBUTIONSTATSMAP = new Map();
for (let i = 0; i < DISTRIBUTIONSTATS.length; i++) {
    DISTRIBUTIONSTATSMAP.set(DISTRIBUTIONSTATS[i], DISTRIBUTIONSTATSNAMES[i]);
}

export const PERCENTAGESTATS = [
  "DAYTYPEPERCENTAGE",
  "DAYTYPEWEEKDAY",
  "INITIALBALANCE",
  "SESSIONCLOSE",
  "OVERNIGHT",
  "OPEN_GAPS",
  "OPEN_VA",
  "OPEN_GAPUP",
  "OPEN_GAPDOWN",
  "OPEN_INRANGE",
  "OPEN_INRANGE_ABOVEVALUE",
  "OPEN_INRANGE_BELOWVALUE",
  "OPEN_INRANGE_INVALUE",
  "VPOC_STATS",
  "OR_STATS"
];

export const PERCENTAGESTATSNAMES = [
  "Day Types",
  "Day Types Per Weekday",
  "Initial Balance",
  "Session Close",
  "Overnight",
  "Open vs Prior Session Range",
  "Open vs Prior Session Value Area",
  "Gap Up",
  "Gap Down",
  "Open In Range",
  "Open In Range, Above Value",
  "Open In Range, Below Value",
  "Open In Range, In Value",
  "VPOC Statistics",
  "Opening Range"
];

export const PERCENTAGESTATSMAP = new Map();
for (let i = 0; i < PERCENTAGESTATS.length; i++) {
  PERCENTAGESTATSMAP.set(PERCENTAGESTATS[i], PERCENTAGESTATSNAMES[i]);
}

export const HEATMAPSTATS = [
  "HEATMAP_RANGE",
  "HEATMAP_VOLUME",
  "HEATMAP_OPENCLOSE"
]

export const HEATMAPSTATSNAMES = [
  "Range",
  "Volume",
  "Trend"
]

export const HEATMAPSTATSMAP = new Map();
for (let i = 0; i < HEATMAPSTATS.length; i++) {
  HEATMAPSTATSMAP.set(HEATMAPSTATS[i], HEATMAPSTATSNAMES[i]);
}

export const HEATMAPTIMEFRAMES = [15,30,60]
export const HEATMAPTIMEFRAMESNAMES = [  
  "15 min",
  "30 min",
  "1 hour"
]

export const HEATMAPTIMEFRAMESMAP = new Map();
for (let i = 0; i < HEATMAPTIMEFRAMES.length; i++) {
  HEATMAPTIMEFRAMESMAP.set(HEATMAPTIMEFRAMES[i], HEATMAPTIMEFRAMESNAMES[i]);
}
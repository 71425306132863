import { Button, Card, styled } from '@mui/material';
import { lighterColor } from 'app/utils/utils';
import { useTheme }from '@mui/material';
import { NavLink } from 'react-router-dom';

const CardRoot = styled(Card)(({ theme }) => ({
  marginBottom: '24px',
  padding: '24px !important',
  [theme.breakpoints.down('sm')]: { paddingLeft: '16px !important' },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  position: 'relative',
  padding: '24px !important',
  //background: lightMainThemeColor,//`rgb(${convertHexToRGB(theme.palette.primary.main)}, 0.15) !important`,`rgb(${convertHexToRGB(theme.palette.primary.main)}, 0.15)`
  [theme.breakpoints.down('sm')]: { padding: '16px !important' },
}));

const Paragraph = styled('p')(({ theme }) => ({
  margin: 0,
  paddingTop: '24px',
  paddingBottom: '24px',
  color: theme.palette.text.secondary,
}));

const UpgradeCard = () => {
  const { palette } = useTheme();
  const lightMainThemeColor = lighterColor(palette.primary.main, 0.8);
//<img src="/assets/images/illustrations/upgrade.svg" alt="upgrade" />
  return (
    <CardRoot>
      <StyledCard elevation={0} sx={{ background: lightMainThemeColor }}>
        <img src="/assets/images/logo_chartspots_circle_50x50.png" alt="upgrade" />

        <Paragraph>
          Unlock a world of insights with <strong>MarketStats Pro</strong>
        </Paragraph>

        <Button
          size="large"
          color="primary"
          variant="contained"
          sx={{ textTransform: 'uppercase' }}
        >
          <NavLink
            to="https://www.chartspots.com/marketstats/upgrade"            
            target="_blank"   // Set target="_blank" to open in a new tab
            rel="noopener noreferrer"   // Add rel="noopener noreferrer" for security
            >
               Upgrade and access all statistics
          </NavLink>
         
        </Button>
      </StyledCard>
    </CardRoot>
  );
};

export default UpgradeCard;

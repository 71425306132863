import useAuth from 'app/hooks/useAuth';
import { Navigate, useLocation } from 'react-router-dom';
import { routeRoles } from './routeRoles';
import { Alert, Box, Typography } from '@mui/material';
import UpgradeCard from 'app/views/dashboard/shared/UpgradeCard';


const AuthGuard = ({ children, stattype }) => {
  const { isAuthenticated, user } = useAuth();
  const { pathname } = useLocation();

  //When signing out while on a AuthGuard protected route, user object becomes null
  if (!user) return <Navigate replace to="/session/signin" state={{ from: pathname }} />; 
  

  const roles = routeRoles[pathname] || [];

  /*
  console.log('pathname '+pathname);
  console.log('user '+user.role); //USER = NULL when signing out = Error FIX
  console.log('roles '+roles);
  console.log('stattype '+stattype);
  */
  

  // check if route is accessible for user's role
  let isRoleAllowed = roles.includes(user.role);

  if (isRoleAllowed && stattype) isRoleAllowed=checkStattypePermissions(user.role, stattype);

  if (isAuthenticated && isRoleAllowed) return <>{children}</>;

  if (isAuthenticated && !isRoleAllowed) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        minHeight="100vh"
      >
        <Alert severity="error" sx={{ pt: '20px', pl: '40px', pr:'70px'}}>
          <Typography  sx={{ mb: '40px'}} >
            Please upgrade to access this content
          </Typography>
          <UpgradeCard />
        </Alert>
      </Box>
    );
  }

  return <Navigate replace to="/session/signin" state={{ from: pathname }} />;
};

// Function to check the role-based permissions for the stattype
const checkStattypePermissions = (role, stattype) => {
  const formattedStattype = stattype.replace(/^DISTRIBUTION_/, ''); // Remove leading 'DISTRIBUTION_'
  const routePath = `/distribution/${formattedStattype.toLowerCase()}`;
  const requiredPermission = routeRoles[routePath];
  return requiredPermission ? requiredPermission.includes(role) : false;
};

export default AuthGuard;
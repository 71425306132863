import { authRoles } from "./authRoles";

export const routeRoles = {
    '/dashboard/default': authRoles.guest,
    '/charts/echarts': authRoles.admin,
    //...other routes

    //Free tier, however this is an old page, might not be needed as we can use the proper /historical/ links instead
    '/initialbalance/historical': authRoles.guest,

    //Distribution routes
    '/distribution/ib' : authRoles.guest, //FREE
    '/distribution/extup' : authRoles.user,
    '/distribution/extdown' : authRoles.user,
    '/distribution/session' : authRoles.user,
    '/distribution/volume_session' : authRoles.user,
    '/distribution/volume_ovn' : authRoles.user,
    '/distribution/rotation_up' : authRoles.user,
    '/distribution/rotation_down' : authRoles.user,
    '/distribution/or' : authRoles.user,
    '/distribution/or_break1_up' : authRoles.user,
    '/distribution/or_break2_up' : authRoles.user,
    '/distribution/or_break1_down' : authRoles.user,
    '/distribution/or_break2_down' : authRoles.user,

    //Historical routes
    '/historical/daytypepercentage': authRoles.user,
    '/historical/daytypeweekday': authRoles.user,
    '/historical/initialbalance': authRoles.guest, //FREE
    '/historical/sessionclose': authRoles.guest, //FREE
    '/historical/overnight': authRoles.user,
    '/historical/open_gaps': authRoles.user,
    '/historical/open_va': authRoles.user,
    '/historical/open_gapup': authRoles.user,
    '/historical/open_gapdown': authRoles.user,
    '/historical/open_inrange': authRoles.user,
    '/historical/open_inrange_abovevalue': authRoles.user,
    '/historical/open_inrange_belowvalue': authRoles.user,
    '/historical/open_inrange_invalue': authRoles.user,
    '/historical/vpoc_stats': authRoles.user,
    '/historical/or_stats': authRoles.user,

    //Heatmap routes
    '/heatmap/openclose': authRoles.user,
    '/heatmap/range': authRoles.user,
    '/heatmap/volume': authRoles.user,

  };
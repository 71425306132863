import { createContext, useEffect, useReducer } from 'react';
//import axios from 'axios';
import axiosInstance from 'app/utils/axiosInstance';
import { MatxLoading } from 'app/components';

const initialState = {
  user: null,
  isInitialised: false,
  isAuthenticated: false
};

//was commented out in original file
/*const isValidToken = (accessToken) => {
  if (!accessToken) return false;
  const decodedToken = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp > currentTime;
};*/

const setSession = (token) => {
  if (token) {
    localStorage.setItem('token', token);
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    localStorage.removeItem('token');
    delete axiosInstance.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT': {
      const { isAuthenticated, user } = action.payload;
      return { ...state, isAuthenticated, isInitialised: true, user };
    }

    case 'LOGIN': {
      const { user, token } = action.payload;
      // Set the access token and configure the axios instance
      setSession(token);
      return { ...state, isAuthenticated: true, user };
    }

    case 'LOGOUT': {
      // Clear the access token and reset the axios instance
      setSession(null);
      return { ...state, isAuthenticated: false, user: null };
    }

    case 'REGISTER': {
      const { user } = action.payload;

      return { ...state, isAuthenticated: true, user };
    }

    default:
      return state;
  }
};

const AuthContext = createContext({
  ...initialState,
  method: 'JWT',
  login: () => {},
  logout: () => {},
  register: () => {}
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const login = async (username, password) => {
    //console.log('calling  localhost:7000/auth');
    //alert('axios before login localhost:7000/auth');
    const response = await axiosInstance.post('/auth', { username, password });
    //alert('axios after login 19212122 localhost:7000/auth');
    const { user, token } = response.data;
    //console.log(user);

    dispatch({ type: 'LOGIN', payload: { user, token } });
  };

  const register = async (username, password) => {
    const response = await axiosInstance.post('/signup', { username, password });
    const { user } = response.data;

    dispatch({ type: 'REGISTER', payload: { user } });
  };

  const logout = () => {
    dispatch({ type: 'LOGOUT' });
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
    }

    (async () => {
      try {
        //console.log(axiosInstance.defaults.headers);
        const { data } = await axiosInstance.get('/profile');
        //console.log(data);
        dispatch({ type: 'INIT', payload: { isAuthenticated: true, user: data.user } });        
      } catch (err) {
        //console.error(err);
        dispatch({ type: 'INIT', payload: { isAuthenticated: false, user: null } });
      }
    })();

    return () => {
      /*This is necessary because after logging out, the user's access token should no longer be stored 
      in local storage and should not be sent along with future requests. By removing the Authorization header, 
      the axiosInstance object will no longer include the token in requests, even if it is still stored in local storage.
      */
      delete axiosInstance.defaults.headers.common.Authorization;
    };
  }, []);

  // SHOW LOADER
  if (!state.isInitialised) return <MatxLoading />;

  return (
    <AuthContext.Provider value={{ ...state, method: 'JWT', login, logout, register }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
